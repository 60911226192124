import React, { useState, useEffect } from "react";
import ElementContainer from "../../../shared/ElementContainer";
import Modal from "../../../shared/Modal";
import LoadingSpinner from "../../../shared/LoadingSpinner";
import { FaTimes } from "react-icons/fa";

const CmraBlocksElement = (props) => {
  const {
    title,
    title_color,
    description,
    id,
    requirable,
    status,
    token,
    completed,
    target_url,
    button_image_url,
    active_track,
    replay_enabled,
    replay_disable_override,
    user,
  } = props;

  let allowReplay = false;

  const [initialize, setInitialize] = useState(false);
  const [display, setDisplay] = useState(false);
  const [hideSpinner, setHideSpinner] = useState(false);
  const [replayEnabled, setReplayEnabled] = useState(false);
  const closeDisplay = () => setDisplay(false);

  const cursor = {
    cursor: "pointer",
  };

  const closeButton = {
    borderBottomLeftRadius: 15,
    position: "fixed",
    top: 0,
    right: 0,
  };

  const buttomImage = {
    maxWidth: 600,
  };

  // When component mounts...
  useEffect(() => {
    if (replay_enabled == undefined) {
      setReplayEnabled(!replay_disable_override);
      return;
    }
    if (replay_enabled && !replay_disable_override) {
      setReplayEnabled(true);
    }
  }, [null]);

  return (
    <ElementContainer
      id={id}
      color={title_color}
      title={title}
      icon={"bx-laptop"}
      requirable={requirable}
      status={status}
      completed={completed}
    >
      {description ? (
        <div
          className="trix-content m-bottom-1"
          dangerouslySetInnerHTML={{ __html: description }}
        />
      ) : null}

      <a onClick={() => setInitialize(true)}>
        <div
          onClick={() => setDisplay(true)}
          style={cursor}
          className="btn btn-secondary expand radius drop-shadow"
        >
          <img
            style={buttomImage}
            src={
              button_image_url
                ? button_image_url
                : "https://cs2n.s3.amazonaws.com/assets/shared/launch_block_activity.png"
            }
          />
        </div>
      </a>

      {initialize ? (
        <Modal display={display} modalId={id} closeDisplay={closeDisplay}>
          <div className="barb btn-cs2n-red" style={closeButton}>
            <FaTimes className="icon-medium" /> Close Activity
          </div>
          {hideSpinner ? null : <LoadingSpinner />}
          <iframe
            id="cmra_blocks"
            src={`https://${target_url}.cs2n.org/index.html?id=${id}&track_id=${active_track}&replay=${replayEnabled}&token=${token}&baseUrl=${window.location.origin}`}
            onLoad={() => setHideSpinner(true)}
          />
        </Modal>
      ) : null}
    </ElementContainer>
  );
};

export default CmraBlocksElement;
