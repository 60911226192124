import React from "react";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { okaidia } from "react-syntax-highlighter/dist/esm/styles/prism";

import SimpleContainer from "../../../shared/SimpleContainer";

const CodeSnippetElement = (props) => {
  const { content, description, language, title, title_color } = props;

  return (
    <SimpleContainer color={title_color} title={title} icon={"bx bx-code"}>
      {description ? (
        <div
          className="trix-content m-bottom-1"
          dangerouslySetInnerHTML={{ __html: description }}
        />
      ) : null}

      <SyntaxHighlighter
        className="code-snippet full-width"
        language={language}
        style={okaidia}
        showLineNumbers={true}
      >
        {content}
      </SyntaxHighlighter>
    </SimpleContainer>
  );
};

export default CodeSnippetElement;
