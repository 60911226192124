import React, { useEffect, useState, useRef } from "react";
import "../../../../style_sheets/carousel.scss";

import GenericContent from "./GenericContent";
import CodeSnippetElement from "./CodeSnippetElement";
import AccordionContent from "./AccordionContent";
import EmbeddableContent from "./EmbeddableContent";
import EmbeddedPageElement from "./EmbeddedPageElement";
import VideoElement from "./VideoElement";

const components = {
  GenericContent: GenericContent,
  CodeSnippetElement: CodeSnippetElement,
  AccordionContent: AccordionContent,
  EmbeddableContent: EmbeddableContent,
  VideoElement: VideoElement,
};

const CarouselElement = ({ elements, title, description }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const ref = useRef([]);

  const goToNext = () => {
    currentIndex < elements.length - 1
      ? setCurrentIndex((currentIndex) => currentIndex + 1)
      : setCurrentIndex(0);
  };

  const goToPrev = () => {
    currentIndex > 0
      ? setCurrentIndex((currentIndex) => currentIndex - 1)
      : setCurrentIndex(elements.length - 1);
  };

  return (
    <div>
      <h2 className="marginless">{title}</h2>
      {description ? (
        <div
          className="trix-content m-bottom-1"
          dangerouslySetInnerHTML={{ __html: description }}
        />
      ) : null}
      <div className="carousel-container">
        <div className="carousel-btn prevButton" onClick={() => goToPrev()}>
          &lt;
        </div>
        <div className="carousel-viewport">
          <CarouselItem element={elements[currentIndex]} />
        </div>
        <div className="carousel-btn nextButton" onClick={() => goToNext()}>
          &gt;
        </div>
      </div>
    </div>
  );
};

const CarouselItem = (props) => {
  const ElementName = components[props.element.type];
  return (
    <ElementName
      title={props.element.title}
      title_color={props.element.title_color}
      body={props.element.body}
      description={props.element.description}
      content={props.element.content}
      language={props.element.language}
      key={props.element.id}
      url={props.element.url}
      top_description={props.element.top_description}
      bottom_description={props.element.bottom_description}
      autoplay={props.element.autoplay}
      looping={props.element.looping}
    />
  );
};

export default CarouselElement;
