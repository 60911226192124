import React from "react";
import { FaSpinner } from "react-icons/fa";

const LoadingSpinner = () => {
  const spinnerStyle = {
    backgroundColor: "white",
    position: "absolute",
    textAlign: "center",
    top: "45%",
    left: 0,
    width: "100%",
    padding: 10,
    color: "gray",
  };

  const spinnerIcon = {
    fontSize: 72,
  };

  return (
    <div style={spinnerStyle}>
      <FaSpinner style={spinnerIcon} className="bx-loader-circle bx-spin" />
      <p className="m-bottom-0">Loading. Please wait..</p>
    </div>
  );
};

export default LoadingSpinner;
