import React from "react";

const SimpleContainer = (props) => {
  const { icon, title, color } = props;
  const borderLine = {
    borderBottom: "1px solid black",
    borderColor: color,
    paddingBottom: "1em",
  };

  return (
    <div className="f-col-s12 padless m-bottom-2 element-simple">
      {title == "" ? null : (
        <div
          className="f-col-s12 p-left-0 p-right-0 p-top-0 flex f-d-row m-bottom-1"
          style={borderLine}
        >
          {icon == null ? null : (
            <span className="flex f-a-self-center">
              <i className={`bx ${icon} icon-medium`} />
            </span>
          )}
          <h2 className="marginless expand">{title}</h2>
        </div>
      )}

      {props.children}

      <div className="clearfix" />
    </div>
  );
};

export default SimpleContainer;
