import React, { useEffect, useState } from "react";
import { FaCheck } from "react-icons/fa";
import consumer from "../../channels/consumer";

const ElementContainer = (props) => {
  const { id, completed, requirable, reviewable, color, title, status, icon } =
    props;

  const [hasCompleted, setCompleted] = useState(false);
  const isP3G = (compare) => {
    const p3g_games = [
      "Play Acceleration City!",
      "Play Super Slime Battle!",
      "Play Zillah City: Fall of the Toxibots",
      "Play Zillah Beats",
      "Play Ghosts of the USS Salazar",
    ];
    if (p3g_games.includes(compare)) {
      return true;
    }
    compare.includes("Play Acceleration City!");
  };
  // USE EFFECT: COMPONENT MOUNT
  useEffect(() => {
    if (requirable) {
      setCompleted(completed);
      // if (!isP3G(title)) {
      //   const sub = consumer.subscriptions.create("UserRequirementChannel", {
      //     // Called once when the subscription is created.
      //     initialized() {
      //       // Used for initialization
      //     },
      //     // Called when the subscription is ready for use on the server.
      //     connected() {
      //       // sub.perform("confirm_subscription"); // This will trigger logs on the server
      //     },
      //     // Called when the WebSocket connection is closed.
      //     disconnected() {
      //       console.warn(
      //         title,
      //         ": Client disconnected to User Requirement Channel"
      //       );
      //     },
      //     // Called when the subscription is rejected by the server.
      //     rejected() {
      //       console.warn(
      //         title,
      //         ": Client rejected to User Requirement Channel"
      //       );
      //     },
      //     received(data) {
      //       if (data.element_id != id) {
      //         return;
      //       }
      //       // Replace data.element.id with the actual ID you have
      //       var element = document.getElementById(
      //         `requirement_${data.type}_${data.element_id}`
      //       );
      //       if (element) {
      //         element.style.color = "limegreen";
      //         setCompleted(true);
      //       }
      //     },
      //   });
      //   window.addEventListener("beforeunload", () => {
      //     sub.consumer.disconnect();
      //   });
      // }
      // // Cleanup function to unsubscribe when the component unmounts
      // return () => {
      //   sub.unsubscribe();
      // };
    }
  }, []);

  const borderLine = {
    borderBottom: "1px solid black",
    borderColor: color,
    paddingBottom: "1em",
  };

  return (
    <div className="f-col-s12 padless m-bottom-2 element-complex">
      <div
        className="f-col-s12 p-left-0 p-right-0 p-top-0 flex f-d-row m-bottom-1"
        style={borderLine}
      >
        <span className="flex f-a-self-center">
          {icon && !requirable ? (
            <i className={`bx ${icon} icon-medium`} />
          ) : null}
          {requirable ? (
            <RequirementStatus completed={hasCompleted} icon={icon} />
          ) : null}
        </span>

        <div className="flex f-d-column expand">
          <h2 className="marginless">{title}</h2>
          {reviewable ? <ReviewableStatus status={status} /> : null}
        </div>
      </div>

      {props.children}

      <div className="clearfix" />
    </div>
  );
};

const RequirementStatus = (props) => {
  const completed = props.completed;
  const icon = props.icon;
  const id = props.id;
  const iconStatus = {
    backgroundColor: completed ? "limegreen" : "none",
    display: "flex",
    padding: completed ? "5px" : "0 5px",
    margin: completed ? "0 5px 0 0" : "0",
    borderRadius: 5,
  };

  const checkColor = {
    color: "white",
    paddingLeft: 5,
  };

  return (
    <span style={iconStatus}>
      <i
        id={"requirement_" + id}
        className={completed ? `bx ${icon}` : `bx ${icon} icon-medium`}
        style={completed ? { color: "white" } : { color: "gray" }}
      />
      {completed ? <FaCheck style={checkColor} /> : null}
    </span>
  );
};

const ReviewableStatus = (props) => {
  const { status } = props;
  const fontSize = {
    fontSize: ".9em",
    padding: "2px 4px",
  };

  switch (status) {
    case "incomplete":
      return (
        <div className="f-col-s12 padless">
          <div
            className="f-col-s12 secondary font-size-half radius"
            style={fontSize}
          >
            <i className="bx bxs-error-circle primary-text m-right-half" />
            This upload must be verified. Upload to continue..
          </div>
        </div>
      );

    case "submitted":
      return (
        <div className="f-col-s12 padless">
          <div
            className="f-col-s4 radius p-right-0 primary font-size-half"
            style={fontSize}
          >
            <i className="bx bx-check-circle secondary-text m-right-half" />
            Uploaded
          </div>
          <div
            className="f-col-s8 radius-right p-left-0 secondary font-size-half"
            style={fontSize}
          >
            <i className="bx bxs-error-circle primary-text m-right-half" />
            Awaiting verifications..
          </div>
        </div>
      );

    case "rejected":
      return (
        <div className="f-col-s12 padless">
          <div
            className="f-col-s4 radius-left p-right-0 primary font-size-half"
            style={fontSize}
          >
            <i className="bx bx-check-circle secondary-text m-right-half" />
            Uploaded
          </div>
          <div
            className="f-col-s4 radius-right p-right-0 p-left-0 primary font-size-half"
            style={fontSize}
          >
            <i className="bx bx-check-circle secondary-text m-right-half" />
            Verified
          </div>
          <div
            className="f-col-s4 radius-right p-left-0 secondary font-size-half"
            style={fontSize}
          >
            <i className="bx bxs-x-circle danger-text m-right-half" />
            Rejected..
          </div>
        </div>
      );

    case "completed":
      return (
        <div className="f-col-s12 padless">
          <div
            className="f-col-s4 radius-left p-right-0 primary font-size-half"
            style={fontSize}
          >
            <i className="bx bx-check-circle secondary-text m-right-half" />
            Uploaded
          </div>
          <div
            className="f-col-s4 p-right-0 p-left-0 primary font-size-half"
            style={fontSize}
          >
            <i className="bx bx-check-circle secondary-text m-right-half" />
            Verified
          </div>
          <div
            className="f-col-s4 radius-right p-left-0 primary font-size-half"
            style={fontSize}
          >
            <i className="bx bx-check-circle secondary-text m-right-half" />
            Accepted
          </div>
        </div>
      );
  }

  return <div></div>;
};

export default ElementContainer;
